
import React from "react"
import Layout from "../components/layout"
import '../styles/pages/blogs.scss'
import { StaticImage } from "gatsby-plugin-image"

const Ftc = () => {

  return <Layout>
  <div className="blogs-index">
    <h1 className="title">Hubble Contacts Settles with U.S. Federal Trade Commission (FTC) </h1>
    <h4>Company Moving Forward with Plans to Expand its eCommerce Business and Introduce New Eye Care Products and Services</h4>
    <div className="dots-wrapper">
      <StaticImage src='../images/gray-dots-dark.svg' />
    </div>

    <div style={{"textAlign": "left"}}>
      <p>
        New York (January 28, 2022) – Vision Path, Inc., which operates Hubble Contacts (Hubble), today announced it reached an agreement with the U.S. Federal Trade Commission (FTC) to settle a dispute related to Hubble’s compliance with the Fairness to
        Contact Lens Consumers Act and the Contact Lens Rule.
      </p>
      <p>
        “We are relieved to put this behind us and continue our journey to become one of
        the leading vision companies in the world,” said Steven Druckman, CEO of Vision
        Path. “The FTC’s allegations relate to a period when the company was just starting up,
        and all requirements in the order were addressed long ago through improvements to our systems and internal processes.”
      </p>
      <p>
        Founded in 2016, Vision Path has evolved from a disruptive newcomer into a category
        leader for affordable contact lenses and other vision products.  Through its online
        platforms, Vision Path sells contact lenses and glasses under the Hubble brand
        as well as other contact lens brands through its companion website, ContactsCart.
        Both sites offer prices for contact lenses that are among the lowest in the industry.
        “Hubble entered a concentrated market by offering consumers something innovative and new:
         affordable prices and convenient subscriptions for daily disposable
        contact lenses,” said Mr. Druckman. “We acknowledge that Hubble, like many other
        disruptive startups, experienced some operational challenges early on, but through
        continuous improvement those have been corrected as Hubble matured and
        evolved.” Mr. Druckman added that “Hubble is a very different company today than
        it was when we launched the business.”
      </p>
      <p>
        Now that Hubble and ContactsCart are established as leading online retailers of
        quality lenses at affordable prices, Vision Path is moving rapidly to expand its
        offerings across the vision category. Hubble recently introduced prescription glasses,
        sunglasses, and blue light-filtering glasses under its Frames by Hubble brand. The
        company is exploring the possibility of offering other ancillary eye care products
        and is expanding relationships with multiple leading eye care brands that are
        seeking to take advantage of Vision Path’s innovative and highly efficient online platform
        and customer interface.
      </p>
      <p>
        As a component of the settlement, Hubble has agreed to provide financial
        compensation to certain consumers and also pay a fine. Vision Path is confident that it
        has resolved the FTC’s concerns and has long operated in compliance with the Contact Lens Rule and other FTC requirements.
      </p>
      <p>
        “We disagree with many of the FTC’s claims, including the FTC’s characterization of
        the Hubble team’s intentions, but we believe that this settlement is the best way for
        Hubble to move forward so we can focus on executing our strategy to grow and
        evolve the company and expand on our value proposition,” Mr. Druckman said.
        <br></br>
        <br></br>
      <p><u>About Vision Path Inc.</u></p>
      <p></p>
        Hubble Contacts and ContactsCart are wholly owned by Vision Path, Inc., a
        Delaware corporation headquartered in New York. Founded by Benjamin Cogan and
        Jesse Horwitz in 2016, Hubble sells its own brand of contact lenses through
        www.hubblecontacts.com, and other major contact lens brands through
        www.contactscart.com – in each case at industry leading prices via convenient subscriptions
        to consumers with valid contact lens prescriptions.
      </p>
    </div>
  </div>
</Layout>
}

export default Ftc
